import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './material.module';
import {RouterModule} from '@angular/router';
import {CardImageComponent} from './components/card-image/card-image.component';
import {CardImageOnlyComponent} from './components/card-image-only/card-image-only.component';
import {HttpClientModule} from '@angular/common/http';
import {IdCallerDirective} from './directive/id-caller.directive';
import {IdCalleeDirective} from './directive/id-callee.directive';
import {Card3dDirective} from './directive/card-3d.directive';
import {HeaderComponent} from './components/header/header.component';

const modules = [
    MaterialModule,
    CommonModule,
    RouterModule,
    HttpClientModule
];

const components = [
    IdCallerDirective,
    IdCalleeDirective,
    Card3dDirective,
    CardImageComponent,
    CardImageOnlyComponent,
    HeaderComponent
];

@NgModule({
    declarations: [
        ...components
    ],
    imports: [
        ...modules
    ],
    exports: [
        ...modules,
        ...components
    ]
})
export class SharedModule {
}
