<ng-container *ngFor="let item of navItem">
    <ng-container *ngIf="!item.submenus">
        <a [routerLink]="item.path"
           [ngClass]="{'menu-button': isLargeScreen, spacing: true}"
           mat-menu-item
           routerLinkActive="active">
            {{item.label}}
        </a>
    </ng-container>
    <ng-container *ngIf="item.submenus">
        <a mat-menu-item class="spacing"
           [class.active]="router.isActive(item.path, false)"
           [matMenuTriggerFor]="submenuRef">{{item.label}}</a>
        <mat-menu #submenuRef="matMenu">
            <button *ngFor="let submenu of item.submenus"
                    class="first-letter-capitalize"
                    mat-menu-item [routerLink]="item.path + '/' + submenu">{{submenu}}</button>
        </mat-menu>
    </ng-container>
</ng-container>