import {Directive, ElementRef, HostBinding, HostListener, OnInit} from '@angular/core';

const degrees = (radians: number) => {
    return radians * (180 / Math.PI);
};

@Directive({
    selector: '[appCard3d]'
})
export class Card3dDirective<Ele extends HTMLElement = HTMLElement> implements OnInit {

    @HostBinding('style.transform-style')
    transformStyle = 'preserve-3d';

    @HostBinding('style.transition')
    transition = '500ms ease';

    private nativeElement: Ele;

    constructor(
        private readonly elRef: ElementRef<Ele>
    ) {
    }

    ngOnInit(): void {
        this.nativeElement = this.elRef.nativeElement;
    }

    @HostListener('mouseover', ['$event'])
    handleMouseOver(event: MouseEvent) {
        if (this.nativeElement) {
            /*
            *
            const bd = document.getElementsByTagName('body').item(0);
            const bdst = bd.scrollTop;
            const bdsl = bd.scrollLeft;
            const offsets = this.nativeElement.getBoundingClientRect();
            const w = this.nativeElement.clientWidth || this.nativeElement.offsetWidth || this.nativeElement.scrollWidth;
            const h = this.nativeElement.clientHeight || this.nativeElement.offsetHeight || this.nativeElement.scrollHeight;
            const wMultiple = 320 / w;
            const offsetX = 0.52 - (event.pageX - offsets.left - bdsl) / w;
            const offsetY = 0.52 - (event.pageY - offsets.top - bdst) / h;
            const dy = (event.pageY - offsets.top - bdst) - h / 2;
            const dx = (event.pageX - offsets.left - bdsl) - w / 2;
            const yRotate = (offsetX - dx) * (0.07 * wMultiple);
            const xRotate = - (dy - offsetY) * (0.01 * wMultiple);
            const imgCSS = 'rotateX(' + xRotate + 'deg) rotateY(' + yRotate + 'deg)';
            this.nativeElement.style.transform = imgCSS;
             *
             * */
        }
    }

    @HostListener('mouseleave')
    handleMouseLeave() {
        if (this.nativeElement) {
            this.nativeElement.style.transform = '';
        }
    }

}
