<mat-toolbar [class.border-bottom]="scrolled" class="bg-white">
  <mat-toolbar-row [class.height]="!scrolled" class="container">
    <img alt="parcaune" class="pointer" height="30px" routerLink="/" src="assets/images/parcaune.png">
    <span class="spacer"></span>
    <span *ngIf="(isSmall$ | async); else largeMenuRef">
      <button [matMenuTriggerFor]="smallMenuRef" class="menu spacing" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
    </span>
  </mat-toolbar-row>
</mat-toolbar>

<router-outlet></router-outlet>

<footer class="py-5 bg-white border-top">
  <div class="container">
    <div class="row">
      <div class="col-md-8 pt-2 pb-5 text-center text-md-left">
        <a *ngFor="let item of navItem"
           [routerLink]="item.path"
           mat-button>
          {{item.label}}
        </a>
        <a mat-button routerLink="/copyright">Impressum</a>
      </div>

      <div class="col-md-4 text-center text-md-right">
        <a href="https://twitter.com/parcaune" mat-icon-button rel="noopener" target="_blank">
          <mat-icon class="blue" svgIcon="twitter"></mat-icon>
        </a>
      </div>

      <div class="col-12 text-center">
        <small class="text-secondary">Copyright © 2020 - Parcaune GmbH. Alle Rechte vorbehalten</small>
      </div>
    </div>
  </div>
</footer>

<mat-menu #smallMenuRef>
  <app-header [navItem]="navItem" [isLargeScreen]="false"></app-header>
</mat-menu>

<ng-template #largeMenuRef>
  <span>
    <app-header class="large-screen" [navItem]="navItem" [isLargeScreen]="true"></app-header>
  </span>
</ng-template>
