import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appIdCallee]'
})
export class IdCalleeDirective implements OnInit, OnDestroy {

    @Input()
    appIdCallee: string;
    private subscription: Subscription;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly elRef: ElementRef<HTMLDivElement>
    ) {
    }

    ngOnInit(): void {
        this.subscription = this.activatedRoute
            .queryParams
            .pipe(
                filter(params => !!params.zone_id),
                map(({zone_id}) => zone_id),
                filter(zoneId => zoneId === this.appIdCallee),
            )
            .subscribe(() => this.elRef.nativeElement.scrollIntoView());
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
