import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderModel } from './header.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() public navItem: HeaderModel[] = [];
  @Input() public isLargeScreen: boolean = true;

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

}
