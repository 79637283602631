import {Directive, HostListener, Input} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
    selector: '[appIdCaller]'
})
export class IdCallerDirective {

    @Input()
    appIdCaller: string;

    @Input()
    route: string;

    constructor(
        private readonly router: Router,
    ) {
    }

    @HostListener('click')
    handleClick() {
        this.router
            .navigate([this.route], {
                queryParams: {
                    zone_id: this.appIdCaller,
                }
            })
            .then();
    }

}
