import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.scss']
})
export class CardImageComponent implements OnInit {

  @Input() large = false;
  @Input() cursor = true;
  @Input() transparent = true;
  @Input() round = false;
  @Input() image: string;
  @Input() prefix = '/assets/images/performance/';

  constructor() { }

  ngOnInit() {
  }

}
