import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-image-only',
  templateUrl: './card-image-only.component.html',
  styleUrls: ['./card-image-only.component.scss']
})
export class CardImageOnlyComponent implements OnInit {

  @Input() imageUrl: string;

  constructor() {
  }

  ngOnInit() {
  }

}
