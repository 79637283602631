import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {CompanySubmenu} from './common/constant/enum';
import { HeaderModel } from './common/components/header/header.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public navItem: Array<HeaderModel> = [
    {label: 'Home', path: '/home'},
    {label: 'Leistungen', path: '/performance'},
    {label: 'Über uns', path: '/company', submenus: Object.values(CompanySubmenu)},
    {label: 'Karriere', path: '/career'},
    {label: 'Kontakt', path: '/contact'},
  ];

  scrolled: boolean;
  isSmall$: Observable<boolean>;
  protected subscription = new Subscription();

  constructor(
    protected breakpointObserver: BreakpointObserver,
    public router: Router
  ) {
    this.isSmall$ = this.breakpointObserver
      .observe('(max-width: 991px)')
      .pipe(map(m => m.matches));
    const sub = this.router
      .events
      .pipe(filter(e => (e instanceof NavigationEnd)))
      .subscribe(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.handleScroll);
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  protected handleScroll = () => {
    const of = window.pageYOffset;
    const com = 54;
    this.scrolled = of > com;
  };
}
