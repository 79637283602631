import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {SharedModule} from './common/shared.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {PreloadAllModules, PreloadingStrategy} from '@angular/router';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: PreloadingStrategy, useClass: PreloadAllModules},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
