<mat-card [class.pointer]="cursor"
          appCard3d
          class="hover-elevation p-md-5 card-3d">
    <mat-card-title-group>
        <img *ngIf="image && large"
             [class.round]="round"
             [class.transparent]="transparent"
             alt="image"
             class="mb-3"
             matCardImageMedium
             src="{{prefix}}{{image}}">
        <img *ngIf="image && !large"
             [class.round]="round"
             [class.transparent]="transparent"
             alt="image"
             class="mb-3"
             matCardImageSmall
             src="{{prefix}}{{image}}">

        <mat-card-title class="font-weight-bolder break-words spacing">
            <ng-content select="span[role=title]"></ng-content>
        </mat-card-title>
    </mat-card-title-group>

    <mat-card-content>
        <ng-content select="p"></ng-content>
        <ng-content select="div[role=content]"></ng-content>
    </mat-card-content>

    <mat-card-actions>
        <ng-content select="button"></ng-content>
        <ng-content select="a"></ng-content>
    </mat-card-actions>
</mat-card>
